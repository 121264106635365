import { Cmp } from ":mods";
import { TopProfile, TopNotifications } from "./navs";

export enum ENDPOINTS {
  // GET
  get_token_details = "auth/token/details",
  get_logout = "auth/logout/",
  get_events = "events/list",

  // POST
  post_token = "auth/token/",
  post_account_sign_up_student = "/account/sign_up_student/",
  post_account_sign_up_tutor = "/account/sign_up_tutor/",
  // post_token_refresh = "auth/token/refresh/",
  post_password_reset = "auth/password-reset/",
  post_password_reset_confirm = "auth/password-reset/confirm/",
  post_password_reset_validate_token = "auth/password-reset/validate_token/",
  // PUT
  put_password_update = "/auth/password-update/",
  // PATCH
  patch_account_otp = "/account/confirm_email_otp/",

  verify_email = "/account/update_profile_email/",
  verify_email_otp = "/account/verify_user_otp/",
  get_profile_details = "/account/profile/details/{id}/",
  get_regions = "/account/country/regions/{country}/",
  get_countries = "/account/countries/",
  update_profile_image = "/account/update-profile-image/",
}

export enum TOKEN_TYPE {
  admin = "admin",
  student = "student",
  tutor = "tutor",
  // participant = "participant",
  // filterer = "filterer",
  // mentor = "mentor",
  // judge = "judge",
  // evaluator = "evaluator",
  // auditor = "auditor",
  // prioritizer = "prioritizer",
}

export const GENDERS_LIST = ["male", "female"];
export const LOCATIONS_LIST = ["Riyadh", "Jeddah", "Dammam", "Mecca", "Khobar"];

export const ROUTES = {
  auth: {
    login: "/login",
    signup: "/signup",
    password_reset: "/password_reset/:token"
    // otp: "/signup/otp",
  },
  profile: {
    settings: "/profile",
  },
};
